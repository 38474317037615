import React from "react";
import Speaker from "./speaker";

export default ({ data }) => {
  let groupedRows = data.reduce((arr, item, idx) => {
    if (idx % 3 === 0) {
      arr.push(data.slice(idx, idx + 3));
    }
    return arr;
  }, []);
  return (
    <React.Fragment>
      {groupedRows.map(row => (
        <React.Fragment>
          <div className="flex-container top-align">
            {row.map(item => (
              <div className="_3cols-box reduced-margin" key={item.id}>
                <Speaker data={item} />
              </div>
            ))}
          </div>
          <div className="sm-spacing" />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
