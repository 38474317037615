import React from "react";
import { graphql } from 'gatsby'
import moment from "moment";
import ReactMarkdown from "react-markdown";
import EventSpeakers from "../components/event/speakers";
import SpeakerModal from "../components/event/speaker-modal";
import SocialShare from "../components/common/social-share";
import { HelmetDatoCms } from "gatsby-source-datocms";
import {
  NUGIT_DATE_FORMAT,
  formatDateRange,
  resetWebflow,
  createHubSpotForm,
  isPastADate,
  resizeAndConvertImage
} from "../utils/common";
import storytellingPop from "../styles/images/storytelling-pop.svg";
import triangleRight from "../styles/images/triangle-right.svg";
import triangleLeft from "../styles/images/triangle-left.svg";
import EventGallery from "../components/event/gallery";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import Notification from "../components/header/notification";
import Layout from "../layouts";

const defaultMapOptions = {
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: false,
  mapTypeControl: false
};

const MyMapComponent = withScriptjs(
  withGoogleMap(props => {
    let { isMarkerShown, location } = props;
    let geoLocation = null;
    if (location) {
      geoLocation = { lat: location.latitude, lng: location.longitude };
    }
    return (
      <GoogleMap
        defaultOptions={defaultMapOptions}
        defaultZoom={16}
        defaultCenter={geoLocation}
      >
        {isMarkerShown && <Marker position={geoLocation} />}
      </GoogleMap>
    );
  })
);

export default ({
  data: {
    datoCmsEventDetail: {
      title,
      hubspotFormId,
      addressLocation,
      buttonText,
      formDescription,
      subTitle,
      eventDisplayTime,
      eventStartTime,
      eventEndTime,
      detail,
      address,
      speakers,
      seoMetaTags,
      slug,
      coverPhoto,
      gallery,
      addToCalendar,
      partners,
      feature: isFeature,
      notificationPage
    },
    datoCmsGlobalConfiguration: { googleMapsKey },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  if (hubspotFormId) {
    createHubSpotForm({
      formId: hubspotFormId,
      css: "",
      targetId: "#EventSignupForm"
    });
  }
  resetWebflow("5b6a6475072047636e41e90d");
  let dateRange = formatDateRange(
    null,
    moment(eventStartTime),
    moment(eventEndTime)
  );
  let headerTmpl =
    slug === "the-future-is-data-storytelling" ? (
      <header className="main-header event">
        <div className="data-storytelling-pop">
          <img src={storytellingPop} alt="data-storytelling" />
        </div>
        <div className="triangle-right w-hidden-tiny">
          <img src={triangleRight} alt="triangle-right" />
        </div>
        <div className="triangle-left w-hidden-tiny">
          <img src={triangleLeft} alt="triangle-1" />
        </div>
      </header>
    ) : (
        <header
          className="main-header event"
          style={{ backgroundImage: `url(${coverPhoto && coverPhoto.url}${resizeAndConvertImage(1600)})`, backgroundPosition: "50% 50%" }}
        />
      );
  return (
    <Layout>
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <div
        data-w-id="a06bc94f-668b-91eb-de5b-950a30f2a522"
        style={{ display: "none" }}
        className="tint-nugit"
      >
        <div
          data-w-id="a06bc94f-668b-91eb-de5b-950a30f2a523"
          style={{
            transform:
              "translate3d(0PX, -150PX, 0PX) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
          }}
          className="close-popup"
        />
        <div
          data-w-id="a06bc94f-668b-91eb-de5b-950a30f2a524"
          style={{
            transform:
              "translate3d(0PX, -150PX, 0PX) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
          }}
          className="popup-bg form"
        >
          <h1 className="h-nofixedwidth">{buttonText}</h1>
          <div className="sm-spacing" />
          <ReactMarkdown source={formDescription} escapeHtml={false} />
          <div className="sm-spacing" />
          <div className="w-embed w-script">
            <div id="EventSignupForm" />
          </div>
        </div>
      </div>
      <HelmetDatoCms seo={seoMetaTags} />
      {headerTmpl}
      <div className="white-section event">
        <div className="container grid valign">
          <div className="event-title">
            {!isFeature && !isPastADate(eventStartTime) && (
              <React.Fragment>
                <div className="p">
                  {moment(eventStartTime).format(NUGIT_DATE_FORMAT)}
                </div>
                <div className="tn-spacing" />
              </React.Fragment>
            )}
            <h1 className="h-fixedwidth">
              {title}
              <br />
            </h1>
            <div className="tn-spacing" />
            <div className="small-uppercase">{subTitle}</div>
            <div className="sm-spacing" />
            <SocialShare horizontal={true} />
          </div>
          <div className="event-cta">
            {buttonText && (
              <a
                data-w-id="604b4147-29de-0ce0-978c-ed7e3182444c"
                className={`btn w-button`}
                target="_blank"
              >
                {buttonText}
              </a>
            )}
          </div>
          <div className="md-spacing" />
          <div className="divider" />
          <div className="md-spacing" />
        </div>
        <div className="container grid">
          <div className="_2cols-box _60">
            {!isFeature && (
              <React.Fragment>
                <h5 className="h5">Event Details</h5>
                <div className="sm-spacing" />
                <ReactMarkdown source={detail} escapeHtml={false} />
                {buttonText && (
                  <React.Fragment>
                    <div className="sm-spacing"></div>
                    <a
                      data-w-id="5ee74e4d-95f6-7d5e-e38a-2acade6efddd"
                      className={`btn w-button`}
                      target="_blank"
                    >
                      {buttonText}
                    </a>
                  </React.Fragment>

                )}
                {!!(speakers && speakers.length) && (
                  <React.Fragment>
                    <div className="md-spacing"></div>
                    <h5 className="h5">Our Speakers</h5>
                    <div className="sm-spacing" />
                    <EventSpeakers data={speakers} />
                    <div className="md-spacing" />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {!!gallery.length && (
              <React.Fragment>
                <h5 className="h5">Past Event Gallery</h5>
                <div className="sm-spacing" />
                <EventGallery images={gallery} />
                <div className="md-spacing" />
              </React.Fragment>
            )}

            {!!partners.length && (
              <React.Fragment>
                <h5 className="h5">Our Partners</h5>
                <div className="sm-spacing" />
                <div className="flex-container top-align">
                  {partners.map(image => (
                    <div className="client-logo" key={image.id}>
                      <img src={image.url} alt={image.alt} width="180" />
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>

          {!isFeature && (
            <div className="_2cols-box _32">
              <div className="shadow-box full-w">
                {addressLocation && (
                  <div className="event-embed-map">
                    <MyMapComponent
                      isMarkerShown
                      location={addressLocation}
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `200px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </div>
                )}
                <div className="event-map-content w-clearfix">
                  <div className="sm-spacing" />
                  <h6 className="h6 location">Address</h6>
                  <div className="tn-spacing" />
                  <p className="small-copy">
                    <ReactMarkdown source={address} escapeHtml={false} />
                  </p>
                  <div className="sm-spacing" />
                  <div className="divider" />
                  <div className="sm-spacing" />
                  <h6 className="h6 time">Date &amp; Time</h6>
                  <div className="tn-spacing" />
                  <p className="small-copy">{eventDisplayTime || dateRange}</p>
                  <div className="sm-spacing" />
                  {!!addToCalendar && (
                    <a
                      href={addToCalendar}
                      target="_blank"
                      className="learn-more center"
                    >
                      Add To Calendar
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {speakers.map(speaker => (
        <SpeakerModal data={speaker} />
      ))}
    </Layout>
  );
};

export const query = graphql`
  query EventDetailQuery($slug: String!) {
    datoCmsEventDetail(slug: { eq: $slug }) {
      slug
      title
      hubspotFormId
      formDescription
      buttonText
      addressLocation {
        latitude
        longitude
      }
      subTitle
      eventDisplayTime
      eventStartTime
      eventEndTime
      detail
      address
      addToCalendar
      partners {        
        url
        alt
      }
      coverPhoto {
        url
      }
      gallery {        
        alt
        url
      }
      speakers {
        id
        name
        role
        bio
        profilePicture {
          url
        }
      }
      feature
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      notificationPage {
        id
        page
      }
    }
    datoCmsGlobalConfiguration {
      googleMapsKey
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
